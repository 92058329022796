<template>
	<div class="bg">
		<headers :userList="userinfor"></headers>
		<div class="box">
			<div class="title">
				邀请记录
			</div>
			<div class="list">
				<div class="card" v-for="(item,index) in list" :key="index" v-if="list">
					<div class="top">
						<div class="img">
							<img :src="item.avatar">
						</div>
						<div class="infor">
							<p>{{item.user_nickname}} <span class="">{{item.user_level_txt}}</span></p>
							<p>账号：{{item.user_login}}</p>
						</div>
					</div>
					<p class="time">注册时间：{{item.create_time}}</p>
				</div>
				<div v-else class="noth">
					暂无数据
				</div>
			</div>
			
		</div>
		<publicBottom></publicBottom>
	</div>
</template>

<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		usergetinfo
	} from "@/request/user";
	export default {
		name: "invitation",
		components: {
			headers,
			publicBottom,
		},
		data() {
			return {
				userinfor: "",
				list: [],
			}
		},
		created() {
			//   个人信息
			usergetinfo({
				token: this.$token
			}).then(res => {
				if (res.code == 1) {
					this.userinfor = res.data;
				}
			});
			this.getlist(); //获取邀请记录列表
		},
		methods: {
			getlist() {
				this.$axios.post("/api/user/invrecord", {
					token: this.$token,
				}).then(res => {
					if (res.data.code == 1) {
						this.list = res.data.data;
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.bg {
		background-color: #f5f5f5;
	}
	.noth{
		text-align: center;
		line-height: 600px;
		color:#CCCCCC;
		font-size: 30px;
	}
	.box {
		width: 1200px;
		background-color: #fff;
		margin: 20px auto;

		.title {
			line-height: 50px;
			padding: 0 20px;
			border-bottom: 1px solid #f5f5f5;
		}

		.list {
			display: flex;
			flex-wrap: wrap;
			min-height: 600px;
		}

		.card {
			width: 33.33333333%;
			height: 220px;
			padding: 40px;
			border: 1px solid #f5f5f5;

			.top {
				display: flex;

				.img {
					width: 100px;
					height: 100px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.infor {
					margin-left: 40px;

					p {
						margin-bottom: 40px;
					}
				}
			}
		}
	}
</style>
